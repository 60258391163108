<template>
    <div class="container">
      <ul class="menu">
        <li v-for="(item, index) in menuItems" :key="index" class="menu-item">
          <router-link :to="item.path">{{ item.name }}</router-link>
        </li>
      </ul>
    </div>
  </template>
  
  <script>
  export default {
    name: 'MenuPage',
    data() {
      return {
        menuItems: [
          { name: '用租车审批', path: '/index' },
          { name: '无接触食堂访客申请', path: '/canteen' },
          { name: '请假申请', path: '/page3' },
          { name: '菜单项 4', path: '/page4' },
          { name: '菜单项 5', path: '/page5' },
          { name: '菜单项 6', path: '/page6' },
          { name: '菜单项 7', path: '/page7' },
          { name: '菜单项 8', path: '/page8' }
        ]
      };
    }
  };
  </script>
  

  <style scoped>
  .container {
    padding: 20px;
  }
  
  .menu {
    list-style-type: none;
    padding: 0;
  }
  
  .menu-item {
    margin: 10px 0;
  }
  
  .menu-item a {
    text-decoration: none;
    color: #4A4A4A; /* 柔和的深灰色 */
    font-size: 16px;
    transition: color 0.3s ease;
  }
  
  .menu-item a:hover {
    color: #007BFF; /* 亮蓝色用于悬停效果 */
  }
  </style>
  
  
  <style scoped>
  .container {
    max-width: 600px;
    width: 100%;
    margin: 0 auto;
    padding: 20px;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  }
  h1 {
    text-align: center;
    margin-bottom: 20px;
  }
  .menu {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
  .menu-item {
    padding: 15px;
    margin: 5px 0;
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 4px;
    cursor: pointer;
    text-align: center;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    transition: background-color 0.3s, box-shadow 0.3s;
  }
  .menu-item:hover {
    background-color: #eaeaea;
    box-shadow: 0 4px 8px rgba(0,0,0,0.2);
  }
  </style>